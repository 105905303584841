<template>
  <div>
    <p>Costos y Precios</p>
    <v-row>
      <v-col>
         <Lista />
      </v-col>
    </v-row>
  </div>
 </template>
 
 <script> 
 import Lista from './componentes/Lista.vue';
 export default {
   components: {
    Lista
   },
 }
 </script>
 