<template>
  <div>
    <v-expand-transition>
      <div v-show="showFiltros">
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="3">
            <TipoOrigenProdcutoSelect v-model="dato.IdTipoOrigenProducto" :clearable="true" :outlined="true">
            </TipoOrigenProdcutoSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <TipoProdcutoSelect v-model="dato.IdTipoProducto" :clearable="true" :outlined="true"> </TipoProdcutoSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <TasaImpuestoSelect :padre="1" v-model="dato.IdTasaImpuesto" :clearable="true" :outlined="true">
            </TasaImpuestoSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <UnidadMedidaSelect v-model="dato.IdUnidadMedida" :clearable="true" :outlined="true" :add="false">
            </UnidadMedidaSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <MarcaSelect v-model="dato.IdMarca" :clearable="true" :outlined="true" :add="false"> </MarcaSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <ModeloSelect v-model="dato.IdModelo" :clearable="true" :outlined="true" :add="false"> </ModeloSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <ColorSelect v-model="dato.IdColor" :clearable="true" :outlined="true" :add="false"> </ColorSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <CategoriaSelect v-model="dato.IdCategoria" :clearable="true" :outlined="true" :add="false">
            </CategoriaSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SubCategoriaSelect
              v-model="dato.IdSubCategoria"
              :disabled="dato.IdCategoria == null ? true : false"
              :padre="dato.IdCategoria"
              :clearable="true"
              :outlined="true"
              :add="false"
            >
            </SubCategoriaSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <LineaSelect v-model="dato.IdLinea" :clearable="true" :outlined="true" :add="false"> </LineaSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <SubLineaSelect
              v-model="dato.IdSubLinea"
              :disabled="dato.IdLinea == null ? true : false"
              :padre="dato.IdLinea"
              :clearable="true"
              :outlined="true"
              :add="false"
            ></SubLineaSelect>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <PaisSelect v-model="dato.IdPais" :clearable="true" :outlined="true" :add="false"> </PaisSelect>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
    <v-row>
      <v-col cols="12" md="8" lg="10">
        <v-text-field
          ref="NombreRef"
          dense
          outlined
          label="Descripcion"
          v-model="dato.Nombre"
          autofocus
          placeholder="Nombre/Descripcion/Barra/Ref..."
          v-on:keyup.enter="buscar()"
          :loading="buscando"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" lg="2">
        <v-btn color="primary" block @click="buscar()" :loading="buscando"> Buscar</v-btn>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-card-subtitle>Productos </v-card-subtitle>
                  </v-col>
                  <v-col>
                    <v-select
                      class="mr-4"
                      dense
                      outlined
                      :items="TiposCalculos"
                      label="Tipo Calculo"
                      item-text="descripcion"
                      item-value="id"
                      v-model="TipoCalculo"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>

              <template>
                <v-simple-table fixed-header dense id="scroll-target" style="height: 400px" class="overflow-y-auto">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">#</th>
                        <th class="text-left">Producto</th>
                        <th class="text-left">Costo</th>
                        <th class="text-left">Factror2</th>
                        <th class="text-left">Costo2</th>
                        <th class="text-left">Factror3</th>
                        <th class="text-left">Costo3</th>
                        <th class="text-left">FacVenta</th>
                        <th class="text-left">PVP</th>
                      </tr>
                    </thead>
                    <tbody v-scroll:#scroll-target="onScroll">
                      <tr
                        v-for="(item, i) in filterProductos()"
                        :key="i"
                        :class="item.indCambio ? 'successLight' : colorRow(item, i)"
                      >
                        <td class="text-center">
                          {{ item.idProducto }}
                        </td>

                        <td v-if="columnas.includes('NOMBRE')">
                          <small class="py-0 my-0">{{ item.producto.nombre }} </small> <br />
                          <small class="py-0 my-0"
                            ><strong>{{ item.producto.barra }}</strong>
                          </small>
                        </td>
                        <td>
                          <v-text-field
                            @keyup="calcularCostoPVP(item, 'Costo')"
                            class="mt-1"
                            dense
                            outlined
                            v-model="item.costo"
                            style="width: 90px"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            @keyup="calcularCostoPVP(item, 'FactorCosto2')"
                            class="mt-1"
                            dense
                            outlined
                            v-model="item.factorCosto2"
                            style="width: 90px"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            @keyup="calcularCostoPVP(item, 'Costo2')"
                            class="mt-1"
                            dense
                            outlined
                            v-model="item.costo2"
                            style="width: 90px"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            @keyup="calcularCostoPVP(item, 'FactorCosto3')"
                            class="mt-1"
                            dense
                            outlined
                            v-model="item.factorCosto3"
                            style="width: 90px"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            @keyup="calcularCostoPVP(item, 'Costo3')"
                            class="mt-1"
                            dense
                            outlined
                            v-model="item.costo3"
                            style="width: 90px"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            @keyup="calcularCostoPVP(item, 'FactorVenta')"
                            class="mt-1"
                            dense
                            outlined
                            v-model="item.factorVenta"
                            style="width: 90px"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            @keyup="calcularCostoPVP(item, 'PVP')"
                            class="mt-1"
                            dense
                            outlined
                            v-model="item.pvp"
                            style="width: 90px"
                          ></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>

              <v-card-text class="text-center">
                <v-btn small :disabled="!cambios" class="primary mt-4" @click="GuardarCambios()" :loading="guardando">
                  Guardar Cambios
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
  <script>
import UnidadMedidaSelect from '@/views/sistema/producto/maestro/unidad-medida/componentes/Select.vue'
import MarcaSelect from '@/views/sistema/producto/maestro/marca/componentes/Select.vue'
import ModeloSelect from '@/views/sistema/producto/maestro/modelo/componentes/Select.vue'
import ColorSelect from '@/views/sistema/producto/maestro/color/componentes/Select.vue'
import CategoriaSelect from '@/views/sistema/producto/maestro/categoria/componentes/Select.vue'
import SubCategoriaSelect from '@/views/sistema/producto/maestro/sub-categoria/componentes/Select.vue'
import LineaSelect from '@/views/sistema/producto/maestro/linea/componentes/Select.vue'
import SubLineaSelect from '@/views/sistema/producto/maestro/sub-linea/componentes/Select.vue'
import PaisSelect from '@/views/sistema/producto/maestro/pais/componentes/Select.vue'
import TipoOrigenProdcutoSelect from '@/views/sistema/producto/maestro/tipo-origen-producto/componentes/Select.vue'
import TipoProdcutoSelect from '@/views/sistema/producto/maestro/tipo-producto/componentes/Select.vue'
import TasaImpuestoSelect from '@/views/sistema/producto/maestro/tasa-impuesto/componentes/Select.vue'
import ProveedorSelect from '@/views/sistema/producto/catalogo/proveedor/componentes/Select.vue'
import { mdiFilterOff, mdiFilter } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import ProductoSucursalServices from '@/api/servicios/ProductoSucursalServices'
import store from '@/store'
import funciones from '@/funciones/funciones'
import { acciones } from '@/modulos'
export default {
  components: {
    UnidadMedidaSelect,
    MarcaSelect,
    ModeloSelect,
    ColorSelect,
    CategoriaSelect,
    SubCategoriaSelect,
    LineaSelect,
    SubLineaSelect,
    PaisSelect,
    TipoOrigenProdcutoSelect,
    TipoProdcutoSelect,
    TasaImpuestoSelect,
    ProveedorSelect,
  },
  props: {
    seleccionMultiple: Boolean,
    columnas: {
      type: Array,
      default: ['ID', 'NOMBRE', 'CODIGO', 'CARACTERISTICA', 'CATEGORIA', 'LINEA', 'EXISTENCIA'],
    },
    IndExistencia: {
      type: Boolean,
      default: true,
    },
    soloExistencia: {
      type: Boolean,
      default: true,
    },
    idArea: {
      type: Number,
      default: -1,
    },
    indLote: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, context) {
    const dato = ref({
      IdTipoOrigenProducto: null,
      IdTipoProducto: null,
      IdUnidadMedida: null,
      IdModelo: null,
      IdMarca: null,
      IdColor: null,
      IdCategoria: null,
      IdSubCategoria: null,
      IdLinea: null,
      IdSubLinea: null,
      IdPais: null,
      Nombre: '',
      IndPeso: null,
      IdTasaImpuesto: null,
    })
    const showFiltros = ref(true)
    const showOnlyExistencia = ref(true)
    const desserts = []
    const NombreRef = ref(null)
    const colorRow = (item, i) => {
      let clas = ''
      //item.checked ? ' ':' info' + !(i % 2) && !item.checked ? 'grey3' : ' ' " @click="item.checked = !item.checked
      if (!(i % 2) && !item.checked) {
        clas = 'grey3 '
      }

      if (item.checked) {
        clas = 'grey4'
      }
      return clas
    }
    const cambios = ref(false)
    watch(props, () => {
      showOnlyExistencia.value = props.soloExistencia
    })
    const calcularCostoPVP = (item, campo) => {
      item.indCambio = true
      cambios.value = true
      switch (campo) {
        case 'Costo':
          item.costo2 = funciones.roundToTwo(item.costo * item.factorCosto2)
          item.costo3 = funciones.roundToTwo(item.costo2 * item.factorCosto3)
          if (TipoCalculo.value == 1) {
            item.pvp = funciones.roundToTwo(item.costo3 * item.factorVenta)
          } else {
            item.factorVenta = funciones.roundToTwo(item.pvp / item.costo3)
          }

          break
        case 'Costo2':
          item.factorCosto2 = funciones.roundToTwo(item.costo2 / item.costo)
          item.costo3 = funciones.roundToTwo(funciones.roundToTwo(item.costo2 * item.factorCosto3))
          if (TipoCalculo.value == 1) {
            item.pvp = funciones.roundToTwo(item.costo3 * item.factorVenta)
          } else {
            item.factorVenta = funciones.roundToTwo(item.pvp / item.costo3)
          }
          break

        case 'FactorCosto2':
          item.costo2 = funciones.roundToTwo(item.costo * item.factorCosto2)
          item.costo3 = funciones.roundToTwo(item.costo2 * item.factorCosto3)
          if (TipoCalculo.value == 1) {
            item.pvp = funciones.roundToTwo(item.costo3 * item.factorVenta)
          } else {
            item.factorVenta = funciones.roundToTwo(item.pvp / item.costo3)
          }
          break
        case 'FactorCosto3':
          item.costo3 = funciones.roundToTwo(item.costo2 * item.factorCosto3)
          if (TipoCalculo.value == 1) {
            item.pvp = funciones.roundToTwo(item.costo3 * item.factorVenta)
          } else {
            item.factorVenta = funciones.roundToTwo(item.pvp / item.costo3)
          }
          break

        case 'Costo3':
          item.factorCosto3 = funciones.roundToTwo(item.costo3 / item.costo2)
          if (TipoCalculo.value == 1) {
            item.pvp = funciones.roundToTwo(item.costo3 * item.factorVenta)
          } else {
            item.factorVenta = funciones.roundToTwo(item.pvp / item.costo3)
          }
          break
        case 'FactorVenta':
          item.pvp = funciones.roundToTwo(item.costo3 * item.factorVenta)
          break

        case 'PVP':
          item.factorVenta = funciones.roundToTwo(item.pvp / item.costo3)
          break
        default:
        // default statements
      }
    }

    const datos = ref([])
    const datosSelect = ref([])
    const buscando = ref(false)
    const limit = ref(20)
    const TipoCalculo = ref(1)
    const TiposCalculos = ref([
      {
        id: 1,
        descripcion: 'Mantener Factor',
      },
      {
        id: 2,
        descripcion: 'Mantener PVP',
      },
    ])
    const filterProductos = () => {
      const datoss = datos.value
      if (datoss.length > 0) {
        return datoss.slice(0, limit.value)
      } else {
        return []
      }
    }
    const offsetTop = ref(0)
    const offsetHeight = ref(0)
    const onScroll = e => {
      offsetHeight.value = e.target.scrollHeight
      offsetTop.value = (e.target.scrollTop + 400).toFixed()
      if (e.target.scrollHeight - 200 <= (e.target.scrollTop + 400).toFixed()) {
        limit.value = limit.value + 20
      }
    }
    const selectProducto = item => {
      if (props.IndExistencia == true) {
        let _existencia = false
        if (item.existencia.inventario > 0) {
          _existencia = true
        } else {
          _existencia = false
        }
        if (item.lote != undefined) {
          if (item.lote.existenciaLote.inventario > 0) {
            _existencia = true
          } else {
            _existencia = false
          }
        }
        if (_existencia == true) {
          item.checked = !item.checked
          if (item.checked) {
            if (datosSelect.value.filter(i => i.codigoLote == item.codigoLote).length > 0) {
              store.commit('setAlert', {
                message: 'El producto ya se encuentra seleccionado',
                type: 'info',
              })
            } else {
              datosSelect.value.push({ ...item })
            }
          } else {
            datosSelect.value = datosSelect.value.filter(i => i.codigoLote != item.codigoLote)
          }
        } else {
          store.commit('setAlert', {
            message: 'No tiene existencia disponible',
            type: 'error',
          })
        }
      } else {
        item.checked = !item.checked
        if (item.checked) {
          if (datosSelect.value.filter(i => i.codigoLote == item.codigoLote).length > 0) {
            store.commit('setAlert', {
              message: 'El producto ya se encuentra seleccionado',
              type: 'info',
            })
          } else {
            datosSelect.value.push({ ...item })
          }
        } else {
          datosSelect.value = datosSelect.value.filter(i => i.codigoLote != item.codigoLote)
        }
      }
    }
    const GetProductos = () => {
      context.emit('GetProductos', datosSelect.value)
    }
    const Limpiar = () => {
      datos.value = []
      datosSelect.value = []
      NombreRef.value.focus()
    }
    const guardando = ref(false)
    const GuardarCambios = async () => {
      let Hash = 0
      if (!funciones.validarAcciones(acciones.COSTO_PRECIO_ACTUALIZAR, store.state.sucursalSelect.id)) {
        Hash = await funciones.HashsConsultar()
      } else {
        Hash = 3
      }

      if (Hash == 3) {
        guardando.value = true
        const datosx = datos.value.filter(item => item.indCambio)

        ProductoSucursalServices.ActualizarMasivo(store.state.user.id, datosx)
          .then(response => {
            if (response.data.estatus) {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'success',
              })
              buscar()
            } else {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'warning',
              })
            }
          })
          .catch(error => {
            store.commit('setAlert', {
              message: 'error' + error,
              type: 'error',
            })
          })
          .finally(() => {
            guardando.value = false
            cambios.value = false
            limit.value = 20
          })
      } else {
        Vue.swal({
          icon: 'error',
          text: `No tienes permiso para realizar esta accion`,
        })
      }
    }

    const buscar = () => {
      datos.value = []
      buscando.value = true
      ProductoSucursalServices.ConsultarAvanzada({
        ...dato.value,
        IdSucursal: store.state.sucursalSelect.id,
        idArea: props.idArea,
        indLote: props.indLote,
      })
        .then(response => {
          if (response.data.estatus) {
            if (response.data.datos.length > 0) {
              datos.value = response.data.datos
            } else {
              store.commit('setAlert', {
                message: 'No hubo resultados',
                type: 'info',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
            message: err,
            type: 'error',
          })
        })
        .finally(() => {
          buscando.value = false
        })
    }

    return {
      dato,
      desserts,
      showFiltros,
      icons: {
        mdiFilterOff,
        mdiFilter,
      },
      buscar,
      datos,
      datosSelect,
      buscando,
      funciones,
      colorRow,
      selectProducto,
      GetProductos,
      Limpiar,
      NombreRef,
      showOnlyExistencia,
      filterProductos,
      offsetTop,
      onScroll,
      offsetHeight,
      TiposCalculos,
      TipoCalculo,
      calcularCostoPVP,
      cambios,
      GuardarCambios,
      guardando,
    }
  },
}
</script>